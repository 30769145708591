.react-select
    &__indicator-separator
        display: none
    &__control
        background: #FFFFFF
        border-radius: 5px
        min-height: auto !important
        height: 38px
        &:hover
            cursor: pointer
        &--is-focused, &:focus, &:hover
            outline: none
            box-shadow: none
            border: 1px solid #C5CBD9
    &__dropdown
        width: 0
        height: 0
        border-style: solid
        border-width: 7px 6px 0 6px
        border-color: #8e99ad transparent transparent transparent
        position: absolute
        right: 12px
        top: 14px
    &__single-value
        font-weight: 700
        font-size: 12px
        text-transform: uppercase
        color: #2F4267 !important
    &__option
        font-weight: 400
        font-size: 12px !important
        text-transform: uppercase
        padding: 8px 0 !important
        background-color: #fff !important
        width: 100%
        &+&
            border-top: 1px solid #E5E5E5
        &:hover
            font-weight: 700
        &:hover
            cursor: pointer
    &__menu
        border: 1px solid #C5CBD9
        border-radius: 5px
        box-shadow: none
        padding: 0 10px

.react-select--mini
    .react-select__control
        height: 34px

.react-select--margin
    margin-bottom: 1rem

.select-admin
    min-width: 220px