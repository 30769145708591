.report_time
  display: flex
  justify-content: space-between
  align-items: center
  border-bottom: 1px solid $light-grey
  padding: 20px 0
  &:last-of-type
    border-bottom: 1px solid transparent
    padding-bottom: 0
  .report_time-date
    padding: 0 65px 0 10px
    font-weight: 400
    font-size: 15px
    color: $some-grey
  .report_time-events
    display: flex
    flex-direction: column
    flex-grow: 1
    gap: 1rem
    .report_time-event
      display: flex
      width: 100%
      // padding-bottom: 15px
      align-items: center
      .time_part
      .report_time-violation
        flex-basis: 35%
        font-size: 13px
        font-weight: 700
      .report_time-limit
        flex-basis: 25%
        font-weight: 400
        font-size: 11px
        text-transform: uppercase
      .report_time-param
        display: flex
        align-items: center
        flex-basis: 25%
        font-weight: 300
        font-size: 11px
      .report_time-param--bold
        font-weight: 700
        font-size: 15px
        padding: 0 8px
.report_time-more
  display: flex
  flex-basis: 15%
  font-weight: 600
  font-size: 11px
  text-transform: uppercase
  text-align: end
  position: relative
  align-items: center
  color: $grey
  transform: all 0.2s ease
  border: none
  background-color: #fff
  &:hover
    color: $dark-grey
  &:hover > .arrow, .time-more_text
    fill: $dark-grey
  .time-more_text
    padding-right: 10px
  .arrow
    fill: $grey 
    &:hover
      color: $dark-grey
      cursor: pointer
      fill: $dark-grey 
    &:hover > .arrow
      fill: $dark-grey 