.charts 
  width: 100%
  color: $dark-grey
  margin: 0 auto
  position: absolute
  top: 0
  left: 0
  bottom: 0
  background-color: #fff
  .charts__container
    display: flex
    flex-direction: column
    padding-bottom: 5rem
    .chart_box
      display: flex
      flex-direction: column
      align-items: center
      .chart__title
        color: #2F4267
        font-weight: 400
        font-size: 34px
        text-align: center
        margin-top: 20px
        strong
          font-weight: 700
    