.profile
  display: flex
  flex-direction: column
  flex-grow: 1
  width: calc(100% - 452px)
  .profile__container
    display: flex
    flex-direction: column
    padding: 30px 70px
    position: relative
    width: 100%
    .profile__border
      border-bottom: 1px solid $dark-grey
    .profile__row
      display: flex
      justify-content: space-between
      position: relative
      flex-grow: 1
      padding-bottom: 20px
      gap: 2rem
      .profile__title
        width: 100%
        text-align: center
        display: block
        color: $dark-grey
        font-weight: 700
        font-size: 34px
        margin-bottom: 46px
      .profile__span
        font-weight: 400
      .profile_address
          margin-top: 10px
      .profile__item
        display: flex
        flex-direction: column
        color: $dark-grey
        .profile__user
          display: flex
          align-items: center
          .profile__isConfirm
            color: $turquoise
            text-transform: uppercase
            font-weight: 600
            font-size: 12px
            position: relative
            &::before
              position: absolute
              content: ""
              width: 5px
              height: 5px
              border-radius: 2.5px
              left: -10px
              top: 3.5px
              background-color: $turquoise
        .company__title
          font-weight: 700
          font-size: 15px
          padding-bottom: 16px
        .company__name
          font-weight: 700
          font-size: 24px
        .company__address
          font-weight: 400
          font-size: 13px
        .company__inn
          padding-top: 10px
          font-weight: 400
          font-size: 12px
          .company__span
            margin-right: 10px
            position: relative
            &::after
              position: absolute
              content: ""
              width: 1px
              height: 100%
              top: 0
              right: -5px
              background-color: $grey
            &:last-of-type:after
              background-color: transparent
      .profile__item--long
        flex-grow: 1
    .profile__row--center
      align-self: center
      .profile__item--full
        display: flex
        flex-basis: 100%

.company__role
  font-weight: 400
  font-size: 11px
  text-transform: uppercase
  .company__current-role
    color: $turquoise
    font-weight: 600
    font-size: 13px
    text-transform: none

.profile__input
  border-radius: 5px
  border: 1px solid $light-grey
  padding: 5px
  &:focus
    border: 1px solid $dark-grey
    outline: none
.profile__input--error
  border: 1px solid $red-error
.profile__input--long
  flex-grow: 1
.profile__input--none
  border: transparent
  background-color: #fff
.modal__button
  outline: none
  color: $dark-grey
  background-color: $light-light-grey
  border-radius: 18px
  text-transform: uppercase
  font-weight: 700
  font-size: 12px
  margin: 0 0 0 10px
  padding: 10px 30px

  border: 1px solid transparent
  transition: all 0.1s ease
  white-space: nowrap
  &:disabled
    pointer-events: none
    filter: grayscale(1)
  &:hover
    background-color: $turquoise
    color: #fff
    border: 1px solid transparent
  
.modal__button--white
  border: 1px solid $light-grey
  border-radius: 20px
  background-color: #fff
  &:hover
    background-color: $turquoise
    color: #fff
    border: 1px solid transparent
.modal__button--grey-hover
  &:hover
    background-color: $middle-grey
.modal__button--red
  margin-left: 0
  &:hover
    background-color: $red-error
.modal__button--small
  padding: 10px 18px
.modal__button--big
  padding: 13px 48px
  margin: 20px 0
.profile-edit__label
  padding-bottom: 12px
.modal-edit__button-box
  margin-top: 20px
  display: flex
  justify-content: flex-end
.profile__item
  margin-bottom: 1rem
