.ctnTitle{
    display: flex;
    background: rgb(233, 235, 241);
    align-items: center;
    width: 100%;
    max-width: 1134px;
    height: 30px;
    padding-left: 10px;
}

.text{
    display: flex;
    width: 100%;
    max-width: 150px;
    color: rgb(37, 56, 93);
    flex: 2 1 auto;
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0%;
    text-align: left;
}

.data{
    padding-left: 13px;
    max-width: 119px;
}

.tread{
    justify-content: space-evenly;
}

.run{
    justify-content: center;
}
