.points_list
  display: flex
  flex-direction: column
  margin: 30px 0
  padding-bottom: 2rem
  .points_item
    display: flex
    align-items: center
    border-bottom: 1px solid $grey
    padding: 10px 0
    &:last-of-type
      border-bottom: none
    .points_part
      display: flex
      flex-direction: column
      margin: 0 5px
      .points_row
        display: flex
        font-weight: 400
        font-size: 12px
        color: $dark-grey
        line-height: 16px
        .points_coordinates
          padding-right: 5px
  .points_item--none
    flex-direction: column
    font-weight: 400
    font-size: 13px
    color: $grey
    line-height: 16px
    padding: 10px 0
.point__title
  padding-right: 20px
  margin-bottom: 12px
  position: relative
  font-weight: 700
  color: #2F4267
  font-size: 15px
.point__title--start
  padding-right: 20px
  position: relative
  font-weight: 700
  color: #2F4267
  font-size: 15px
  margin-bottom: 20px
.point__title--end
  padding-right: 20px
  position: relative
  font-weight: 700
  color: #2F4267
  font-size: 15px
  margin-top: 20px
.stop
  display: flex
  align-items: stretch
  height: 100%
  .stop_pic
    width: 30px
    min-height: 100%
    position: relative
    .stop_line
      width: 4px
      min-height: 100%
      background-color: $light-light-grey
      margin: 0 14px
    .stop_line--start
      margin-top: 10px
    .stop_line--end
      max-height: 40px
    .stop_circle
      position: absolute
      width: 13px
      height: 13px
      top: 10px
      right: 7px
      background-color: $light-light-grey
      border: 2px solid transparent
      outline: 1px solid transparent
      border-radius: 10px
      z-index: 2
      margin: 0
      &:hover
        cursor: pointer
    .stop_circle--first
      top: 0
    .stop_circle--hover
      box-shadow: 0 0 0 2px #ffffff, 0 0 0 3px $grey
      background-color: $grey
    .stop_circle--start
      box-shadow: 0 0 0 2px #ffffff, 0 0 0 3px $grey
      background-color: $green
    .stop_circle--end
      box-shadow: 0 0 0 2px #ffffff, 0 0 0 3px $grey
      background-color: #989696
      top: 20px
  .stop_box
    display: flex
    flex-direction: column
    width: 100%
    margin-bottom: 12px
    max-width: calc( 100% - 27px )
    .stop_info
      display: flex
      justify-content: space-between
      .stop_item
        display: flex
        flex-direction: column
        .stop_text
          font-weight: 400
          font-size: 11px
          color: $dark-grey
          text-transform: uppercase
          padding-bottom: 5px
        .stop_time
          font-weight: 700
          font-size: 12px
          line-height: 16px
          color: $dark-grey
          padding-bottom: 8px
    .stop_address
      display: flex
      align-items: center
      margin-left: 10px
      .address_text
        font-weight: 400
        font-size: 12px
        line-height: 16px
        color: $dark-grey
        border-bottom: 1px solid $dark-grey
        &:hover
          font-weight: 600
          cursor: pointer
      .address_logo
        margin-right: 5px
      .address_logo--mini
        width: 9px
        height: 13px
        margin-right: 0px
  .stop_box--clean
    padding: 0
.point_violation
  padding: 0 10px

.point__checkboxes
  display: flex
  padding: 0 5px
  .point__checkbox
    display: flex
    align-items: center
    padding-right: 16px
    .checkbox__text
      font-weight: 700
      font-size: 11px
      text-transform: uppercase
      color: $dark-grey

.stop__span
  font-weight: 400
  position: relative
  padding-left: 10px
  &::after
    content: ''
    position: absolute
    height: 100%
    background-color: $grey
    width: 1px
    left: 5px
    top: 0
