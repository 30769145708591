.ctnTyres{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.content{
    display: flex;
    gap: 10px;
    padding: 5px;
    border-radius: 5px;
    background-color: #41A95F;
    color: #fff;
    box-shadow: 1px 1px 6px -1px rgba(0, 0, 0, 0.5);
}


.ctnList{
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    gap: 10px;
}