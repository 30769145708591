.ctnBox{
    display: flex;
    width: 100%;
    align-items: center;
    gap: 20px;
    border-bottom: 1px solid rgb(229, 229, 229);
    padding: 10px 0 10px 0;
}

.ctnTittleTyre{
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 100%;
    max-width: 130px;
    padding-left: 10px;
}
.titleModel{
    padding-left: 10px;
    flex: 1 1 auto;
    color: rgb(47, 66, 103);
    font-family: Open Sans;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0%;
    text-align: left;
}

.titleSize{
    padding-left: 10px;
    flex: 1 1 auto;
    color: rgb(142, 153, 173);
    font-family: Open Sans;
    font-size: 11px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0%;
    text-align: left;
}

.ctnDate{
    display: flex;
    justify-content: center;
    padding-left: 10px;
    flex: 1 1 auto;
    width: 100%;
    max-width: 115px;
    color: rgb(47, 66, 103);
    font-family: Open Sans;
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0%;
    text-align: left;
}

.ctnDot{
    display: flex;
    justify-content: center;
    padding-left: 10px;
    flex: 1 1 auto;
    max-width: 80px;
    width: 100%;
    color: rgb(47, 66, 103);
    font-family: Open Sans;
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0%;
    text-align: left;
}
.ctnConstructor{
    padding-left: 10px;
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 265px;
}

.ctnRun{
    display: flex;
    justify-content: center;
    padding-left: 10px;
    flex: 1 1 auto;
    width: 100%;
    max-width: 150px;
    color: rgb(47, 66, 103);
    font-family: Open Sans;
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
}

.ctnTread{
    display: flex;
    justify-content: center;
    padding-left: 10px;
    flex: 1 1 auto;
    width: 100%;
    max-width: 150px;
    color: rgb(47, 66, 103);
    font-family: Open Sans;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0%;
    text-align: left;
}

.ctnLink{
    display: flex;
    align-items: center;
    max-width: 100px;
    width: 100%;
    color: rgb(47, 66, 103);
    font-family: Open Sans;
    font-size: 11px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0%;
    text-align: left;
}

.span{
    padding-right: 5px;
    transition: padding-right 0.3s ease;
}

.active{
    padding-right: 15px;
}