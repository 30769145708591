.ctnSensorInfo{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    max-width: 166px;
    width: 100%;
    height: 25px;
    color: white;
    background-color: #41A95F;
    border-radius: 10px;
}

.text{
    font-size: 14px;
}

.border_left{
    padding-left: 5px;
    border-left: 3px solid white;
}

