.cars-modal
  display: flex
  flex-direction: column
  margin-bottom: 26px
  .cars-modal__button
    border: none
    background-color: transparent
    align-self: flex-start
    font-weight: 400
    font-size: 11px
    color: $dark-grey
    margin-top: 16px
    border-radius: 5px
    padding: 0
    &:hover
      opacity: 0.8
  .car-modal__box
    display: flex
    flex-direction: column
    max-height: 194px
    gap: 6px
    .cars-modal__group
      display: flex
      margin-right: 15px
      position: relative
      flex-direction: column
      &:hover
        cursor: pointer
      .cars-modal__car
        display: flex
        padding: 10px
        width: 100%
        border-radius: 5px
        align-items: center
        position: relative
        transition: all 0.2s ease
        border: 1px solid $light-grey
        &::after
          content: ''
          width: 3px
          height: 100%
          position: absolute
          background-color: transparent
          border-radius: 5px 0 0 5px
          top: 0
          left: 0
          transition: all 0.2s ease
      .cars-modal__car--active
        box-shadow: 5px 5px 10px -10px rgba(0,0,0,0.5) 
        position: relative
        &::after
          content: ''
          width: 3px
          height: 100%
          position: absolute
          background-color: $middle-grey
          border-radius: 5px 0 0 5px
          top: 0
          left: 0
      &_box
        display: flex
  &__folder
    align-self: flex-end
    margin: 0 0 5px
    color: #2F4267
    font-family: Open Sans
    font-size: 10px
    font-style: normal
    font-weight: 600
    text-transform: uppercase
    & > span
      color: #000

.modal-folder__search
  display: flex
  flex-direction: column
  margin-bottom: 20px
  position: relative

.modal-folder__title
  font-weight: 400
  font-size: 15px
  color: $dark-grey
  margin-bottom: 10px
  display: inline-block
  & > span
    font-weight: 300
.modal-folder__body
  display: flex
  align-items: center
.modal-folder__button-box
  display: flex
  justify-content: space-between
.modal-folder__button-box--end
  display: flex
  justify-content: flex-end

