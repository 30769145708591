.user 
  display: flex
  justify-content: space-between
  align-items: center
  margin: 10px 0
.users-box-profile
  padding: 1rem
  margin-top: 20px
  width: 100%
  background-color: $light-light-grey
  border-radius: 10px
.users-box-profile__none
  display: none