.modal-header
  padding: 30px 40px
.modal-body
  padding: 0 40px 30px
  .modal-content__box
    display: flex
    flex-direction: column
    margin-bottom: 20px
  .modal-content__body
    display: flex
    align-items: flex-start
    gap: 1rem
.modal-body--padding
  padding: 20px 15px 5px
.modal-content
  margin-top: 60px
  border-radius: 20px
  font-weight: 400
  font-size: 15px
  line-height: 120%
.modal-confirm__info
  margin-bottom: 16px
.modal-confirm__center
  display: flex
  justify-content: center
.modal__box-big
  max-width: 695px
  .modal-password__container
    display: flex
    justify-content: space-between
    .modal-password__input-box
      position: relative
.modal-password_title
  text-transform: uppercase
  margin-bottom: 10px
  font-size: 13px
  color: $dark-grey
.modal-password_visible
  position: absolute
  border: none
  background-color: transparent
  right: 0px
  top: 5px
.modal-input__border
  border-radius: 5px
  border: 1px solid $light-grey
  padding: 5px
.modal-input__grow
  display: flex
  width: 100%
  flex-grow: 1
  padding: 0.375rem 0.75rem
  font-size: 1rem
  font-weight: 400
  line-height: 1.5
  color: #212529
  background-color: #fff
  border: 1px solid $light-grey
  appearance: none
  border-radius: 0.375rem
  &:read-only
    cursor: default
    pointer-events: none
    opacity: 0.5
.modal-input__grow:focus
  border: 1px solid $dark-grey
  outline: none
.modal-input__grow--error
  border: 1px solid $red-error

.p--modal
  line-height: 130%

.button-exit
  position: absolute
  top: -23px
  right: -34px
  width: 34px
  height: 34px
  border-radius: 17px
  background: #fff url("../images/exit.svg") no-repeat center center
  border: none
  transition: all 0.1s ease
  &:hover
    background: $turquoise url("../images/exit-active.svg") no-repeat center center

.modal-title
  font-weight: 400
  font-size: 27px
  line-height: 30px
  color: #2F4267
  // text-transform: capitalize

.modal-title--margin
  margin: 0 10px 0 10px
  color: $dark-grey
  // text-transform: uppercase
.modal-title--first
  // text-transform: uppercase
  margin: 0 10px 0 0

.modal-notification__container
  display: flex
  margin: 10px 0
  align-items: flex-start
  &:first-of-type
    margin-top: 0
  .modal-notification__box
    display: flex
    justify-content: space-between
    margin-left: 20px
    align-items: center
    .modal-notification__lable
      display: flex
      align-items: center
      cursor: pointer
  .input-area--margin
    margin-bottom: 0
.skelet__container
  margin: 20px 0
  &:first-of-type
    margin-top: 0
.modal__inputs-box
  display: flex
  gap: 20px
.preview_box
.preview__title
  font-weight: 400
  font-size: 12px
  line-height: 16px
  text-transform: uppercase
  color: #2F4267
.preview__border
  height: 4px
  background: #EDEFF4
  width: 100%
  margin-bottom: 20px
.textarea__resize
  resize: none

.modal-dialog--point
  max-width: 442px

.modal-title__half
  width: calc( 50% - 10px )

.modal-title__third
  width: calc( 33% - 10px )

.model-title__bold
  color: $dark-grey
  font-size: 15px
  font-weight: 700
  margin-bottom: 20px

@media ( max-width: 576px )
  .modal__inputs-box
    flex-direction: column
  .modal__inputs-box
    gap: 0px