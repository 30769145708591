.constructor
    &__steps
        padding: 0 10px
        margin-bottom: 2rem
    &__button-save
        margin-top: 1rem
        text-decoration: none
    &__step
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        margin-bottom: 0.4rem
        padding: 5px 10px 20px
        border-radius: 5px

    &__step-header
        display: flex
        align-items: center
        justify-content: space-between
        margin-bottom: 1rem
        width: 100%
    &__step-btn
        background: #FFFFFF
        border: 1px solid #C5CBD9
        border-radius: 50px
        font-weight: 700
        font-size: 11px
        text-transform: uppercase
        color: #2F4267
        padding: 6px 14px
        transition: 0.1s all ease
        &:hover
            background-color: #1CB6C1
            color: #fff
            border-color: #1CB6C1
        &:active
            transform: translateY(1px)
    &__step-title
        font-weight: 700
        font-size: 15px
        color: #2F4267
    &__buttons
        display: flex
        justify-content: center

    &__preview
        transition: all 0.1s ease
        border-radius: 5px
        width: 240px
        min-height: 130px
        text-align: center
        padding: 1rem
        position: relative
        border: 1px solid #C5CBD9
        display: flex
        align-items: center
        justify-content: center
        &--wo-borders
            border: none
    &__preview-title
        font-weight: 600
        font-size: 12px
        text-transform: uppercase
        color: #304267
        position: absolute
        bottom: -6px
        left: 50%
        margin-left: -90px
        background-color: #fff
        width: 180px
    &__items
        display: grid
        gap: 40px 20px
        grid-template-columns: repeat(3, 1fr)
    &__item
        width: 240px
        height: 130px
        border-radius: 5px
        padding: 1rem
        border: 1px solid #C5CBD9
        position: relative
        text-align: center
        display: flex
        align-items: center
        justify-content: center
        transition: 0.1s all ease
        &-title
            font-weight: 600
            font-size: 12px
            text-transform: uppercase
            color: #304267
            position: absolute
            bottom: -6px
            left: 50%
            margin-left: -90px
            background-color: #fff
            width: 180px
            transition: 0.1s all ease
        &-image
            transition: 0.1s all ease
        &:hover
            cursor: pointer
            border-color: #49B869
        &:hover &-title
            color: #49B869
        &:hover &-image
            transform: translateY(-4px)
        &--disabled
            filter: grayscale(1)
            pointer-events: none                

.constructor-page
    display: flex
    flex-direction: column
    width: calc( 100% - 452px )
.constructor-page__container
    display: flex
    flex-direction: column
    align-items: center
    padding: 30px 70px
    position: relative
    width: 100%
    overflow-y: auto

.h2--constructor
    font-weight: 700
    font-size: 34px
    color: #2F4267
    margin-bottom: 2rem
    text-align: center

.constructor-stage
    max-width: 776px
    width: 100%
    &__title
        font-weight: 300
        font-size: 36px
        color: #2F4267
        margin-bottom: 45px
        text-transform: uppercase
    &__text
        font-weight: 600
        font-size: 15px
        text-transform: uppercase
        color: #2F4267
        margin-bottom: 20px
    &__content
        padding: 1rem 0 2rem


        


    