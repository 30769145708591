.ctnLine{
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1134px;
    height: 130px;
    border-bottom: 1px solid rgb(229, 229, 229); ;
}

.text{
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 106px;
    flex: 1 1 auto;
    margin-right: 20px;
    color: rgb(47, 66, 103);
    font-family: Open Sans;
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0%;
    text-align: left;
}


.constructor{
    margin-right: 0;
    max-width: 250px;
    transform: scale(0.8);
}

.bold{
    font-weight: 700;
}

.tread{
    max-width: 168px;
}
.run{
    max-width: 63px;
}


.action{
    justify-content: flex-start;
}