.ctnSupport{
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: 17px;
    gap: 5px;
    @media screen and (max-width: 658px) {
        flex-direction: column;
    }
}

.text{
    color: rgb(47, 66, 103) !important;
    font-family: Open Sans;
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0%;
    text-align: left;
    @media screen and (max-width: 561px) {
        font-size: 9px;
    }
}

.line{
    width: 2px; /* Ширина линии */
    height: 12px; /* Высота линии */
    background-color: rgb(225, 225, 225); /* Цвет линии */ /* Позиционирование */
    left: 50%; /* Центрирование по горизонтали */
    top: 50px; /* Отступ сверху */
    transform: translateX(-50%);
    @media screen and (max-width: 773px) {
        display: none;
    }
   
}


.active{
    transition: transform 0.4s ease, font-weight 0.5s ease;
    &:hover{
        font-weight: 600;
        transform: scale(1.05);
    }
}