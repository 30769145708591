@media (max-width: 1139.9px)
  .profile__border
    flex-direction: column
  .profile
    .profile__container 
      padding: 30px 50px
      .profile__row 
        .profile__item 
          .profile__user
            flex-grow: 1
            justify-content: space-between
  .report_data
    display: grid
    grid-template-columns: 1fr
    grid-template-rows: repeat(2, 1fr)
    gap: 20px
    width: 100%
  .count_events
    grid-template-columns: repeat(2, 1fr)
    grid-template-rows: 1fr
  .report_download
    top: 100%
    right: auto
    left: 50%
    transform: translate(-50%, 0)
    padding: 5px 25px
  .report_date
    margin-top: 40px
  .report_time 
    .report_time-date
      padding: 0 15px 0 5px
  .report_data--single
    display: flex

@media (max-width: 991.9px)
  .report_time 
    .report_time-date
      padding: 0 15px 0 5px
    .report_time-events 
      .report_time-event
        flex-direction: column
        align-items: flex-start
        & > div
          margin-bottom: 10px
  .count_events
    grid-template-columns: 1fr
    grid-template-rows: repeat(2, 1fr)
  
@media (max-width: 767.9px)
  .side-bar__btn
    width: 90%
    margin-top: 5px
  .side-bar__search
    width: calc( 100% - 20px )
    max-width: none
    margin: 10px
  .login-container
    min-width: auto
    width: 100%
  .header-logo
    width: 120px

  .dashboard__view
    width: 50%

  .dashboard__content
    width: 200vw
    transition: 0.2s transform ease
  .dashboard__content.dashboard__content--active
    transform: translateX(-50%)

  .button-exit
    top: 4px
    right: 3px


  .header-navs__toggle
    display: inline-block
    background-color: transparent
    border: none
    font-weight: 700
    font-size: 14px
    text-transform: uppercase
    color: #2F4267
    padding: 6px 10px
    transition: all 0.1s ease
    border-radius: 5px
    &:hover
      background-color: #EDEFF4
      cursor: pointer

  .vehicle-menu__button
    font-size: 12px
    line-height: 1.3

  .side-bar__search .side-bar__input
    width: 100%
    font-size: 14px

  .modal-notification__container
    flex-direction: column

  // .modal-body .modal-content__body
  //   flex-direction: column

  .modal-title
    font-size: 18px
    line-height: 1.1
    font-weight: 600
  .modal-header
    padding: 20px 15px 5px
  .modal-body
    padding: 15px

  .mobile-toggler
    display: inline-block
    margin: 0 auto

  .w100
    width: 100%
  .w50-5
    width: calc(50% - 5px)
  .m0
    margin: 0

  .view__header, .view__inner
    padding: 0 15px

  .input-and-button, .label-and-input
    flex-wrap: wrap

  .point__checkboxes
    margin-top: 10px

  #mobile_btn_map
    display: flex

  .mobile_car_state
    display: flex
    background-color: white
    color: $dark-grey
    width: 100%
    font-weight: 700
    font-size: 14px
    text-transform: uppercase
    color: #2F4267
    box-shadow: 0px 0px 4px rgba(0,0,0,0.25)
    z-index: 1
    position: relative
    padding: 17px 10px
    transition: all 0.1s ease
    &:hover
      background-color: #EDEFF4
      cursor: pointer
    span 
      margin-left: 10px

  .car-navs    
    justify-content: space-around
    .car-desctop
      display: none
    .car-nav
      flex-grow: 1
      span
        width: 100%
        text-align: center
  

  .profile
    width: 50%
    .profile__container 
      padding: 20px

  .side-bar
    width: 50%


@media (max-width: 374.9px)
  .profile 
    .profile__container 
      .profile__row 
        .profile__item 
          .profile__user
            flex-direction: column
            align-items: stretch
  .profile__input
    margin-bottom: 10px
  .side-bar
    min-width: auto
