.map
  &__point
    height: 24px
    width: 24px
    background-color: #fff
    border-radius: 50%
    border: 8px solid #15A3AB
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 50%)
    transition: 0.2s all ease
    outline: 1px solid #fff
    position: relative
    &--G
      border-color: #49B869
    &--G.map__point--slow
      outline: 2px solid rgb(142, 153, 173)
      background-color: rgb(142, 153, 173)
    &--P
      border-color: #7E48B3
    &--Y
      border-color: #F18101
    &--Y.map__point--slow
      outline: 2px solid #F18101
      background-color: #F18101
    &--R
      border-color: #e12c2c
    &--R.map__point--slow
      outline: 2px solid #e12c2c
      background-color: #e12c2c
    &--slow
      border: 4px solid #fff
      height: 18px
      width: 18px
      box-shadow: 0 4px 8px 0 rgb(99 99 99 / 100%)

    .map__marker
      pointer-events: none
      transition: 0.1s opacity ease
      position: absolute
      top: 4px
      right: 10px
      width: 72px
      background-color: #fff
      border-radius: 5px
      line-height: 1
      padding: 4px 5px
      box-shadow: 0 4px 8px 0 rgb(99 99 99 / 50%)
      white-space: nowrap
      text-align: center
      &--right
        right: auto
        left: 10px

    .map__hover
      opacity: 0
      pointer-events: none
      transition: 0.1s opacity ease
      position: absolute
      top: -46px
      left: -22px
      width: 52px
      background-color: #fff
      border-radius: 5px
      line-height: 1
      padding: 4px 5px
      box-shadow: 0 4px 8px 0 rgb(99 99 99 / 50%)
      white-space: nowrap
      z-index: 1
      &--wide
        width: 106px
        left: -48px
      &--widest
        width: 232px
        left: -112px
      /* СТРЕЛКА */ 
      &:after
        top: calc(100% - 2px)
        left: 50%
        border: solid transparent
        content: ""
        height: 0
        width: 0
        position: absolute
        pointer-events: none
        border-color: rgba(255, 255, 255, 0)
        border-top-color: #fff
        border-width: 7px
        margin-left: -7px
        z-index: 2
    &--active
      transform: scale(1.1)
      .map__hover
        opacity: 1
      .map__marker
        opacity: 0.6
    .map__number
        opacity: 1
        top: -56px
        left: -52px
        width: 110px

  &__cluster
    height: 32px
    width: 32px
    background-color: #49B869
    border-radius: 50%
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 50%)
    font-weight: 700
    color: #fff
    text-align: center
    font-size: 14px
    line-height: 32px
    transition: 0.2s all ease
    &--active
      transform: scale(1.1)
    &--G
      background-color: #49B869
    &--P
      background-color: #7E48B3
    &--Y
      background-color: #F18101
    &--R
      background-color: #e12c2c

  &__arrow
    width: 0
    height: 0
    border-style: solid
    border-width: 7px 0 7px 20px
    border-color: transparent transparent transparent #15A3AB
    &--hidden
      display: none
    &--G
      border-color: transparent transparent transparent #49B869
    &--P
      border-color: transparent transparent transparent #7E48B3
    &--Y
      border-color: transparent transparent transparent #F18101
    &--R
      border-color: transparent transparent transparent #e12c2c

.map__balloon-content
  width: 412px

// перекрываю стили яндекса

.ymaps-2-1-79-balloon
  padding: 0 !important
  border-radius: 20px

.ymaps-2-1-79-balloon__layout
  box-sizing: border-box !important
  overflow: visible !important
  background: #fff
  border-radius: 20px

.ymaps-2-1-79-balloon__content
  border-radius: 20px

.ymaps-2-1-79-balloon__close+.ymaps-2-1-79-balloon__content
  margin-right: 0 !important
  padding: 20px 15px !important

.ymaps-2-1-79-balloon__close-button
  position: absolute
  opacity: 1 !important
  top: 4px !important
  right: 4px !important
  height: 34px !important
  width: 34px !important
  border-radius: 50% !important
  background-color: #fff !important
  border: none !important
  background-image: url('../images/map/cross--blue.svg') !important
  &:hover
    background-color: #1CB6C1 !important
    background-image: url('../images/map/cross--white.svg') !important

.map__controls
  position: absolute
  top: 10px
  left: 10px
  padding: 5px 8px
  border-radius: 5px
  background-color: #fff
  box-shadow: 0 2px 8px 0 rgb(99 99 99 / 50%)
  display: flex
  align-items: center
  gap: 1rem