$light-light-grey: #EDEFF4
$light-grey: #C5CBD9
$grey: #8E99AD
$dark-grey: #2F4267
$middle-grey: #4F6288
$another-grey: #283959
$some-grey: #97A1B3
$just-grey: #e5e5e5
$turquoise: #15A3AB
$red-error: #E12C2C
$green: #49B869
$orang: #000
  
*
  box-sizing: border-box

body
  position: relative
  height: 100vh
  overflow: hidden
  font-family: 'Open Sans', sans-serif
  font-size: 16px
  &.main-page
    overflow-y: auto
    overflow-x: hidden

#root
  height: 100%

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

.menu
  position: relative
  z-index: 200
  display: flex
  li
    padding: 5px 10px
    font-size: 15px
    a
      text-decoration-line: none
a, a:link, a:visited, a:hover, a:focus, a:active
  text-decoration-line: none
  color: inherit
  
.map
  width: 100%
  height: 100%

#chartdiv
  position: relative
  &:after
    content: ''
    width: 60px
    height: 20px
    background-color: #fff
    position: absolute
    left: 0
    bottom: 0
    z-index: 1

#canvas
  border: 1px solid #000
  margin: 20px
  box-sizing: content-box

.modal-header,
.modal-footer
  border: none

.css-nvf14r-ToastContainer
  z-index: 1500 !important

.page-container
  padding: 30px
  height: calc(100vh - 60px)
  position: relative
  z-index: 1
  margin-top: 60px
  width: 100%

strong
  font-weight: 700

.h1
  font-family: 'Open Sans', sans-serif
  font-style: normal
  font-weight: 700
  font-size: 34px
  &--cabinet
    color: #2F4267
    margin-bottom: 60px
    display: block

.input-area
  position: relative
  width: 100%
  margin-bottom: 1rem
  .label
    width: 100%
    font-weight: 400
    font-size: 12px
    text-transform: uppercase
    color: #2F4267
    margin-bottom: 0.5rem
  .input
    width: 100%
    &--error
      border: 1px solid #E12C2C

.input-area--no-margin
  margin-bottom: 0

.input-error-message
  color: #E12C2C
  font-weight: 600
  font-size: 11px
  line-height: 15px
  padding: 5px 0px
  & + &
    padding-top: 0
.input-icon
  position: absolute
  right: 10px
  width: 20px
  height: 20px
  top: calc(50% - 10px)
  display: flex
  align-items: center
  justify-content: center

button
  &::selection
    background-color: transparent

.button
  &:disabled
    opacity: 0.6

.button
  border: none
  text-transform: uppercase
  font-weight: 700
  font-size: 12px
  padding: 11px 13px
  transition: 0.1s all ease
  text-decoration: none
  &:active
    transform: translateY(1px)
  &--input
    position: absolute
    top: 19px
    right: 0
  &--mint
    background: #E1F7F5
    border-radius: 5px
    color: #15A3AB
    &:hover
      background-color: #1CB6C1
      color: #fff
  &--map
    background: #E1F7F5
    border-radius: 5px
    color: #15A3AB
    font-size: 11px
    padding: 5px 7px
    &:hover
      background-color: #1CB6C1
      color: #fff
  &--toggle
    display: flex
    align-items: center
    justify-content: center
    padding: 5px 5px
  &--with-label
    margin-top: 24px
  &--login
    background-color: #00b9c4
    color: #fff
    border-radius: 20px
    padding: 14px 56px
    margin: 0 auto
    display: flex
  &--fallback
    background-color: #1CB6C1
    color: #fff
    border-radius: 5px
    &:hover
      color: #fff
  &--middle-grey
    background: $middle-grey
    border-radius: 5px
    color: #fff
    &:hover
      background-color: #1CB6C1
      color: #fff
  &--margin
    margin: 0 10px
  &--margin-bottom
    margin-bottom: 1rem
.input-and-button, .label-and-input
  display: flex
  align-items: center
  justify-content: space-between
  gap: 10px
  align-items: flex-start
  flex: 1

.input-and-button--border
  border: 1px solid $light-grey
  border-radius: 5px
  padding: 10px

.input-and-button--border-top
  padding: 0 5px

.label-and-input
  align-items: center
  gap: 0

.highlighter
  position: relative
  z-index: 1
  &::before
    position: absolute
    content: ''
    top: -1px
    left: -1px
    bottom: -1px
    right: -1px
    background-color: yellow
    border-radius: 3px
    z-index: -1
    border-radius: 5px
    opacity: 0.5

.no-data
  margin-top: 1rem
  margin-bottom: 1rem
  text-align: center
  display: flex
  flex-direction: column
  align-items: center
  padding: 0 2rem
  &__img-container
    margin-bottom: 0.5rem
  &__title
    font-weight: 700
    font-size: 13px
    line-height: 18px
    color: #2F4267
    margin-bottom: 0.5rem
  &__text
    font-size: 13px
    line-height: 18px
    color: #2F4267

.skeleton-loader
  box-sizing: border-box
  margin-top: 1rem

.modal-button-loader
  height: 40px
  width: 80px
  display: flex
  align-items: center
  justify-content: center

.message-warning
  background: #EDEFF4
  font-weight: 600
  font-size: 11px
  border-radius: 3px
  color: #2F4267
  padding: 10px
  text-align: center
  box-shadow: 0px 1px 4px rgba(0,0,0,0.05)

.message-warning_car
  border-top: 1px solid $grey
  border-bottom: 1px solid $grey
  font-weight: 500
  box-shadow: none
  border-radius: 0

.logo_exit
  &:hover
    fill: $middle-grey

.layout
  height: 100vh
  &--public
    background-color: #EDEFF4
    flex: 1 1 auto
    display: flex
    flex-direction: column
  &__navigation
    display: flex
    align-items: center
    width: 100%
    max-width: 756px
    margin: 0 auto

.home-page
  padding-top: 5rem

.h1
  &--404
    font-size: 42px
    color: #2f4267
    font-weight: 700

.p
  &--404
    font-size: 1.5rem
    padding-bottom: 2rem
    color: #2f4267
    font-weight: 700

.h4
  font-size: 21px
  color: #2f4267

.gotoconstructor
  margin: 0 auto
  font-weight: 700
  font-size: 11px
  text-transform: uppercase
  color: #2F4267
  display: flex
  align-items: center
  justify-content: center
  background: #EDEFF4
  border: none
  border-radius: 5px
  height: 25px
  width: 140px
  text-decoration: none
  margin-bottom: 20px
  &:hover
    color: #fff
    background: #1CB6C1

.header-logo
  display: inline-block
  width: 229px

.header-navs__menu
  display: flex
  align-items: center
  justify-content: center
.header-navs__toggle
  display: none
.header-navs__close
  position: absolute
  top: 5px
  right: 5px
  border: none
  background-color: transparent
  border-radius: 3px
  display: none
  align-items: center
  justify-content: center
  padding: 5px
  &:hover
    background-color: #EDEFF4

$headerHeight: 60px

.dashboard
  height: calc(100vh - $headerHeight)
  position: relative
  z-index: 1
  overflow: hidden
  margin-top: $headerHeight

.dashboard__content
  align-content: stretch
  display: flex
  height: 100%

.dashboard__view
  width: calc(100% - 452px)
  height: 100%
  position: relative

.mobile-toggler
  display: none

#mobile_btn_map
  display: none
  span
    margin-right: 10px

.mobile_car_state
  display: none

.pagination
  display: flex
  justify-content: flex-start
.pagination_item
  border-radius: 5px
  color: $dark-grey
  text-align: center
  line-height: 30px
  margin: 0 5px
  font-family: Open Sans
  font-size: 13px
  font-style: normal
  font-weight: 600
  cursor: pointer
.pagination_li
  width: 34px
  height: 30px
  &:hover
    background-color: $light-light-grey
  & > a
    text-decoration: none
.pagination_a
  padding: 0px 10px
  background-color: $light-light-grey
  & > a
    text-decoration: none
    color: $dark-grey

.pagination_active
  background-color: $light-light-grey
.pagination_a--prev
  display: none

.flex
  display: flex
.b-grey 
  border: 1px solid $light-grey
.items-center
  align-items: center
.content-beteween
  justify-content: space-between 
.wrap
  flex-wrap: wrap
.flex-col
  display: flex
  flex-direction: column
.gap
  gap: 10px

.bold 
  font-weight: 700
.tiny
  font-weight: 400
.full
  width: 100%
.half
  flex-basis: calc( 50% - 10px )

.margin-20
  margin-top: 20px
.margin-bottom-20
  margin-bottom: 20px

.margin-bottom-40
  margin-bottom: 40px

.border-text
  width: 100%
  font-weight: 400
  color: $some-grey
  font-size: 15px
  padding: 18px
  border-top: 1px solid $light-grey
  border-bottom: 1px solid $light-grey
  text-align: center

.background-white
  background-color: white


.green
  background-color: green !important

.red
  background-color: red
  width: 30px
  height: 30px

.not_found_text
  display: flex
  justify-content: center
  align-items: center
  font-weight: 400
  font-size: 15px
  color: $some-grey
  padding: 0 0 18px
  margin-top: 20px
  border-bottom: 1px solid $light-light-grey
  width: 100%