.content{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-radius: 20px;
    padding: 15px;
    min-height: 350px; /* Минимальная высота */
    height: auto;
    background-color: white;  
    border: 1px solid #CACBCD; 
    box-shadow: 0px 6px 3px 0 #cacbcd;
}

.active{
    border: 2px solid #058A05;
    box-shadow: 0px 2px 4px 0 #058A05;
}

.header{
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 12px;
}

.contentDevId{
    font-size: 26px;
}

.section{
    display: flex;
    flex-direction: column;
    border-right: 2px solid #f2f2f2;
}


.ctnInfo{
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    gap: 10px;
    height: auto;
}

.itemInfo{
    display: flex;
    height: auto;
    word-wrap: break-word;
    width: 100%;
    flex-direction: column;
    padding: 10px;
    
}

.itemCode{
    max-width: 77px;
}

.strongTittle{
    
    font-size: 15px;
    font-weight: bold;
    line-height: 30px;
}

.ctnSensors{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.border_top{
    border-top: 2px solid #f2f2f2;
}
