.ctnNoData{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px; 
    height: auto;
    width: 100%;
    background-color: white;
    border: 1px solid #CACBCD;
    border-radius: 10px;
}

.text{
    font-size: 17px;
    color: #2F4267;
    text-align: center;
    font-weight: 600;
    
}