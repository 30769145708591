.ctnContent{
    display: flex;
    flex-direction: column;
}

.text{
    color: rgb(47, 66, 103);
    font-family: Open Sans;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0%;
    text-align: left;
}

.ctnInput{
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 10px 0 10px 0;
}

.input{
    width: 100%;
    height: 40px;
    border: 1px solid rgb(197, 203, 217);
    border-radius: 5px;
    background: rgb(255, 255, 255);
    padding: 10px 12px 10px 12px;
    color: rgb(47, 66, 103);
    font-family: Open Sans;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0%;
    text-align: left;
}

.ctnInputSearch{
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
}

.ctnUser{
    display: flex;
    align-items: center;
    margin: 5px 0 5px 0;
    width: 100%;
    height: 100%;
    max-height: 50px;
    box-sizing: border-box;
    border: 1px solid rgb(197, 203, 217);
    border-radius: 5px;
    background: rgb(255, 255, 255);
    cursor: pointer;
}

.textCard{
    display: flex;
    flex-direction: column;
    margin: 10px;
    color: rgb(47, 66, 103);
    font-family: Open Sans;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0%;
    text-align: left;
}

.textEmpty{
    display: flex;
    justify-content: center;
    align-items: center;
    
    padding: 10px;
    border: 1px solid rgb(197, 203, 217);
    color: rgb(47, 66, 103);
    font-family: Open Sans;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0%;
    text-align: left;
}
.CtnButton{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}
.idCard{
    width: 100%;
    max-width: 4px;
}

.activCard{
    border: 2px solid rgb(31, 220, 28);
    box-shadow: 0px 0px 3px rgb(31, 220, 28);
}