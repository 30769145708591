.ctnMoving{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.cntMovingCar{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    // width: 200px;
    height: 30px;
    margin: 10px 0 10px 0; 
}

.ctnBurger{
    display: flex;
    flex-direction: row-reverse;
    position: relative;
    margin-top: 10px;
}

.menuTyre{
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 100;
    margin-top: 7px;
    padding: 4px 21px 4px 17px ;
    // width: 125px;
    visibility: hidden;
    height: 100px;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
    background: rgb(255, 255, 255);
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}
.menuTyre.active {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateY(0);
  }

.textMenu{
    // margin: 5px 0 5px 0;
    color: rgb(47, 66, 103);
    font-family: Open Sans;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0%;
    text-align: left;
    text-transform: uppercase;
}

.titleMenu{
    margin-bottom: 9px;
    color: rgb(47, 66, 103);
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0%;
    text-align: left;
    text-transform: uppercase;
}

.toltiptext {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
    width: 128px;
    height: 30px;
    padding: 10px;
    border-radius: 20px;
    background: rgb(237, 239, 244);
    color: rgb(47, 66, 103);
    font-family: Open Sans;
    font-size: 8px;
    font-weight: 300;
    line-height: 11px;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .toltiptext.active {
    opacity: 1;
    transform: translateY(0);
  }

  @media (max-width: 1605px) {
    .toltiptext {
      margin-top: 3px;
      width: 120px;
    }
  }