.ctnWheel{
    width: 0px;
}

.toggle{
    display: flex;
    justify-content: center;
    align-items: center;
    top: 21px;
    width: 180px;
    left: 2px;
    height: 67px;
    position: absolute;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    background: rgb(255, 255, 255);
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0s 0.3s, pointer-events 0s 0.3s;
}

.toggle.active {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transition: opacity 0.3s ease;
     
  }

.toggleContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 13px;
    position: relative;
}



.textSensor{
    display: flex;
    color: rgb(47, 66, 103);
    font-size: 16px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0%;
    text-align: left;
}