.view-model_list
    display: flex
    align-items: center
    justify-content: center
    position: relative
    width: 130px
    &__container
        display: flex
        align-items: center
        justify-content: center
        flex-direction: row-reverse
        height: 147px
        margin-right: 39px
    .center
        position: absolute
        height: 100%
        width: 2px
        left: 50%
        margin-left: -1px
        top: 0
        height: 100%
        background-color: red
    .wheels_list
        position: absolute
        top: 0
        left: 0
        gap:1vw
        height: 100%
        width: 100%
        display: flex
        align-items: center
        justify-content: center
        flex-direction: row-reverse
.wheel-container_list
    height: 17px
    width: 46px
    display: flex
    align-items: center
    justify-content: center
.wheel_list
    border-radius: 5px
    height: 16px
    width: 64px
    font-weight: 400
    font-size: 11px
    text-align: center
    font-weight: 700
    transition: 0.1s background-color ease
    display: flex
    align-items: center
    justify-content: center
    .hidden-index
        opacity: 0
        transition: 0.1s all ease
    &--mini
        width: 46px
    &--empty
        background-color: #fff
        color: #000
        font-weight: 400
        outline: 1px solid #41A95F 
        outline-offset: -1px
    &--editable
        outline: 1px solid rgb(196, 196, 196)
        background-color: #fff
    &--filled
        outline: 4px solid #41A95F
    &--filled_red
        outline: 4px solid #e12c2c
    &--color_filled
        background: rgb(196, 196, 196)
        font-weight: 400
        outline-offset: -1px
    &--editable:hover
        cursor: pointer
        background-color: #41A95F
        outline: 2px dashed #41A95F
        color: #fff
        .hidden-index
            opacity: 1

    &--red
        outline-width: 2px
        outline-color: #e12c2c
    &--yellow
        outline-width: 2px
        outline-color: #F18101
    &--withoutTyre
        outline: 1px solid $light-grey

.edit-model
    &__buttons
        display: flex
        align-items: center
        justify-content: center
        gap: 1rem
        margin-bottom: 2rem
    &__total
        text-align: center
        margin-bottom: 1rem


