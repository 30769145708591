.ctnUserName{
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 40px;
    @media screen and (max-width: 905px) {
           display: none; 
    }
}   
.userName{
    display: flex;
    align-items: center;
    padding-right: 10px;
    color: rgb(47, 66, 103) !important;
    font-family: Open Sans;
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0%;
    text-align: left;
    word-spacing: 6em;
    transition: transform 0.4s ease, font-weight 0.5s ease;
    &:hover{
        font-weight: 600;
        transform: scale(1.05);
    }
}