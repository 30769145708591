.ctnTC{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 120px;
}

.cntText{
    margin: 40px;
    color: rgb(47, 66, 103);
    font-family: Open Sans;
    font-size: 20px;
    font-weight: 600;
    line-height: 15px;
}