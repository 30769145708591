.login-page
  display: flex
  align-items: center
  justify-content: center
  flex: 1
  flex-direction: column
  padding: 1rem

.login-container
  box-shadow: 0 2px 5px rgb(0 0 0 / 20%)
  background: #fff
  padding: 2rem
  min-width: 500px
  border-radius: 20px

.error-form
  .modal-input__grow
    border: 1px solid #E12C2C

.login
  &__contact-us
    color: #2f4267
    font-size: 11px
    text-align: center
    margin-top: 25px
  &__phone
    text-decoration: none
    color: #2f4267
    font-size: 13px
    font-weight: 800
  &__email
    text-decoration: none
    color: #2f4267
  &__buttons
    margin-top: 1.2rem
  &__reset-href
    font-size: 12px
    color: #2f4267
    text-decoration: none
    text-transform: uppercase
    margin-top: 0.5rem
    display: inline-block
