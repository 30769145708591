.cntNoConstructor{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 40px;
}

.content{
    padding-top: 10px;
    color: rgb(47, 66, 103);
    font-family: Open Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 15px;
}

.img{
    transform: scale(0.8);
}