.checkbox-container
  display: flex
  align-items: center
  cursor: pointer
.checkbox
  display: flex
  align-items: center
  font-size: 0
  height: 22px
  width: 22px
  margin-right: 10px
  &--folder
    margin-right: 0
    margin-left: 15px
  &__input
    display: none
  &__box
    display: inline-block
    border: 2px solid $light-grey
    border-radius: 3px
    width: 22px
    height: 22px
    position: relative
    &:before
      content: ''
      background: url("../images/check.svg") no-repeat center center
      height: 10px
      width: 14px
      transition: 0.1s
      position: absolute
      left: 50%
      top: 50%
      margin-top: -5px
      margin-left: -7px
      opacity: 0
    &--white
      background-color: #fff
      &:before
        height: 12px
        width: 15px
        background: url("../images/check-dark.svg") no-repeat center center
        left: 50%
        top: 50%
        margin-top: -6px
        margin-left: -7.5px
  &:hover &__box
    border-color: $grey
  .checkbox__input:checked + .checkbox__box
    background-color: $grey
    border-color: $grey
    &:before
      opacity: 1
    &--white
      background-color: #fff
      border-color: $light-grey
      &:hover
        border-color: $grey
  .checkbox__input:disabled + .checkbox__box
    background-color: #EDEFF4
    border-color: $light-grey
    &:hover
      border-color: $light-grey

.checkbox-text-container
  &__text
    user-select: none
    margin-right: 20px
