.ctnExit{
    display: flex;
    align-items: center;
}

.ctnLine{
    padding: 0 17px 0 17px;
}

.imgExit{
    cursor: pointer;
}

.tolTip{
    display: flex;
    top: 45px;
    position: absolute;
    border-radius: 3px;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.3);
    background: rgb(255, 255, 255);
}

.text{
    padding: 5px;
    color: rgb(47, 66, 103);
    font-family: Open Sans;
    font-size: 15px;
}