.console
    padding: 0.5rem 0
    &__item
        padding: 0.7rem
        overflow: hidden
        border-radius: 10px
        background-color: #fff
        margin-bottom: 1rem
        gap: 0.4rem
        display: flex
        box-shadow: 1px 1px 6px -1px rgba(0, 0, 0, 0.5)
        flex-direction: column
        color: #000

    &__item-header
        display: flex
        align-items: center
        gap: 1rem
        font-size: 16px

    &__item-date
        font-size: 16px
    &__item-type
        font-size: 16px

    &__item-wheels
        display: flex
        padding: 10px
        flex-wrap: wrap
        gap: 0.5rem
        margin-top: 0.5rem
        margin-bottom: 0.5rem
        border-bottom: 1px solid #CACBCD
        padding-bottom: 20px
    &__title_item_list
        padding-top: 10px    
    &__item-wheel
        display: inline-block
        align-items: center
        justify-content: center
        background-color: #41A95F
        color: #fff
        border-radius: 5px
        font-size: 12px
        padding: 0.2rem
        border-bottom: 1px solid #CACBCD
        padding-bottom: 10px
    &__item-hex
        padding: 10px
        word-break: break-all 
    &__item_container
        display: flex
        flex-direction: column
        padding-top: 10px
        border-bottom: 1px solid #CACBCD
        padding-bottom: 10px

.console--scroll
    margin-right: 20px
    