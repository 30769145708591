.side-bar
  //background-color: $light-light-grey
  //overflow: hidden
  //box-shadow: 5px 5px 10px -10px rgb(0 0 0 / 50%)
  //z-index: 1
  // width: 452px
  //display: flex
  //flex-direction: column
  min-width: 320px
  &__header
    //display: flex
    //flex-direction: column
    //background-color: #fff
    //z-index: 1
  &__top
    //font-family: 'Open Sans', sans-serif
    //height: 35px
    //display: flex
    //justify-content: space-between
    //align-items: center
    //position: relative
  &__btn
    min-height: 35px
    background-color: $middle-grey
    font-weight: 700
    font-size: 11px
    text-decoration: none
    text-transform: uppercase
    border: none
    width: 49.5%
    height: auto
    color: #fff
    padding: 3px 0
    text-align: center
    transition: all 0.2s ease
    &:hover
      background-color: $turquoise
  &__icon
    margin-right: 5px

.side-bar__bottom
  position: relative
  display: flex
  justify-content: space-between
  align-items: center
  padding: 0 10px
  min-height: 45px
  &::after
    content: ''
    position: absolute
    height: 4px
    background-color: $light-light-grey
    bottom: 0px
    left: 20px
    right: 20px
.side-bar__choose
  display: flex
.side-bar__btn-choose
  border: none
  background-color: #fff
  font-weight: 600
  font-size: 13px
  color: #2F4267
  border-radius: 5px
  padding: 6px 10px
  transition: 0.1s all ease
  &:hover
    cursor: pointer
    background-color: #EDEFF4
  &:active
    transform: translateY(1px)
  &--label
    color: #0B0C10
    display: flex
    align-items: center
    span
      // margin-left: 10px
      &::selection
        background-color: transparent
.side-bar__search
  margin-left: 5px
  font-weight: 400
  height: 24px
  max-width: 50%
  font-size: 13px
  background-color: #fff
  outline: 2px solid $light-light-grey
  border-radius: 12px
  display: flex
  align-items: center
  margin-right: 10px
  .side-bar__img
    margin: 0 10px 0 5px
  .side-bar__input
    border: none
    outline: none
    width: 145px
    color: #000
    transition: all 0.2s
    &::-webkit-input-placeholder
      color: $another-grey
    &::-moz-placeholder
      color: $another-grey
  .search__btn
    display: none
    font-weight: 600
    border: none
    background-color: $light-light-grey
    color: $another-grey
    border-radius: 9px
    height: 18px
    margin-right: -4px
    opacity: 0.6
    transition: all 0.2s
    &.search__btn--active
      display: inline-block
      //animation: ani 0.5s
      color: #000
    &:hover
      opacity: 1

.sidebar__menu
  width: 100%
  padding: 0 10px
  text-align: center
  &-item
    width: 100%
  &-link
    font-weight: 400
    font-size: 13px
    color: #2F4267
    text-transform: uppercase
    text-decoration: none
    border: none
    background-color: #fff
    transition: all 0.1s ease
    border-radius: 5px
    padding: 9px 10px
    width: 100%
    display: inline-block
    text-align: left
    // &.active
    //   background-color: #EDEFF4
    strong
      font-weight: 700
    &:hover
      cursor: pointer
      background-color: #EDEFF4
      color: #2F4267
    &--logout
      width: auto
      margin: 0 auto
      margin-top: 1rem
  // &-img
  //   height: 16px
  //   width: 16px
  //   margin-left: 0.5rem

.view-container.sidebar-grey
  background-color: $light-light-grey
  padding: 0
.view__inner.sidebar-white
  background-color: white
  padding-top: 5px 
