.userVehicle
    display: flex
    padding: 10px
    border-radius: 5px
    border: 1px solid #C5CBD9
    background-color: #fff
    align-items: flex-start
    justify-content: space-between
    flex-direction: column
    color: #000
    .userVehicle__name
        width: 100%
        display: flex
        gap: 1rem
    .userVehicle__location
        display: flex
        align-items: center
        gap: 0.3rem
    .userVehicle__statistic
        word-break: break-word
        width: 50%
        padding: 0 10px
    &+&
        margin-top: 0.5rem

    &__buttons
        display: flex
        flex-wrap: wrap
        gap: 0.8rem

    .userVehicle__ignored
        margin-top: 1rem
        margin-bottom: 1rem
        padding: 0 10px

.userVehicles
    margin: 10px
    .userVehicles__message
        margin: 20px 10px