.ctnMain{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}


.ctnMenu{
    display: flex;
}

.ctnOffice{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 20px 5px 20px;
    border-radius: 50px;
    background: rgb(28, 182, 193);
    margin-right: 5px;
    cursor: pointer;

}

.active_background{
    background: rgb(19, 120, 128);
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.3);
}

.active_color{
    color: rgb(242, 242, 245) !important;
}

.imgOpen{
    transition: transform 0.5s ease;
}

.imgOpen.rotate{
    transform: rotate(180deg);
}

.title{
    cursor: pointer;
    color: rgb(252, 253, 255);
    font-family: Open Sans;
    font-size: 13px;
    font-weight: 600;
}

.ctnOpenMenu{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    top: 50px;
    position: absolute;
    padding-left: 20px;
    width: 147px;
    max-height: 0;
    overflow: hidden;
    border-radius: 3px;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.3);
    background: rgb(255, 255, 255); 
    transition: max-height 0.4s ease;
}

.open{
    max-height: 260px;
}

.link{
    padding: 10px 10px 10px 0;
    color: rgb(47, 66, 103);
    font-family: Open Sans;
    font-size: 13px;
    font-weight: 400;
    line-height: 12px;
    &:hover{
        font-weight: 600;
    }
}


