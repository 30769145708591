.tyre-header
    background-color: $light-light-grey
    padding: 9px 0
    width: 100%
    position: relative
.tyre-header-item
    color: $dark-grey
    font-family: Open Sans
    font-size: 12px
    font-style: normal
    font-weight: 700
    line-height: normal
    text-transform: uppercase
    margin: 0px 15px
    position: relative
    &:hover
        color: $dark-grey
    &::before
        position: absolute
        content: ''
        width: 1px
        height: 100%
        border-left: 2px solid $grey
        top: 0
        right: -15px
        transform: skew(30deg)
    &:last-child
        &::before
            border: none
.tyre-header-item--active
    position: relative
    &::after
        position: absolute
        content: ''
        width: 100%
        height: 3px
        background-color: $grey
        bottom: -9px
        left: 0

.tyre_container
    position: relative
    width: 100%
    height: calc( 100vh - 60px )

.tyre_data
  display: grid
  grid-template-columns: repeat(3, 1fr)
  gap: 20px
  width: 100%
  margin-top: 30px

.tyre_table_box 
    display: grid
    
    align-items: center
    width: 100%
    grid-auto-columns: 14% 25% 25% 7% 17% 11%
    grid-auto-flow: column
.tyre_table_box_list
    display: flex
    align-items: center
    gap: 30px
    width: 100%
.tyre_table_box-8
    grid-auto-columns: 10% 16% 16% 11% 17% 10% 10% 10%
.tyre_table_box-5
    grid-auto-columns: 13% 14.5% 9% 27% 10%
 
.tyre_header_box
    height: 40px
    background-color: $light-light-grey
    padding: 7px 0px 7px 10px
.tyre_header_title
    display: flex
    color: $dark-grey
    font-size: 12px
    font-weight: 700
    text-transform: uppercase
    
.tyre_line
    border-bottom: 1px solid $light-light-grey
    align-items: center
    padding-left: 10px
.tyre_line_flex
    flex-direction: column
    margin: 10px
.tyre_line_part
    display: flex
    flex-grow: 1
    width: 121px
    margin: 10px 0
    color: #2F4267
    font-family: Open Sans
    font-size: 13px
    padding-right: 10px
    text-overflow: ellipsis
    word-break: break-all
    &:last-child
        padding-right: 0
.tyre_line_part--white-space
    -webkit-line-clamp: 2 
    display: -webkit-box 
    -webkit-box-orient: vertical 
    overflow: hidden
.buttonMoveCar
    display:flex
   