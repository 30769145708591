.have_point
    // background: linear-gradient(45deg,$turquoise 10%, #fff 35% 100%)
    position: relative
.have_event
    // border-left: 2px solid $turquoise
    // border-right: 2px solid $red-error
    position: relative
.have_event::before
    width: 9px
    height: 3px
    position: absolute
    bottom: 0px
    right: 6px
    border-radius: 3px
    background-color: $red-error
    content: ""
.have_point::after
    width: 9px
    height: 3px
    position: absolute
    bottom: 0px
    left: 6px
    border-radius: 3px
    background-color: $turquoise
    content: ""
    // background: linear-gradient(45deg,$turquoise 10%, #fff 35% 65%, $red-error 90% 100%)
.react-datepicker
    box-shadow: 0px 1px 20px rgba(79, 98, 136, 0.25)
    border-radius: 0
    border: none
    padding: 1rem 0.5rem 0
    &-popper
        z-index: 99
    &-wrapper
        display: inline-block
        width: 100%
    &__triangle
        display: none
    &__header, &__header:not(.react-datepicker__header--has-time-select)
        border-radius: 0
        background-color: #fff
        border-bottom: none
        padding: 0
    &__current-month
        text-transform: capitalize
        color: #2F4267
        font-weight: 600
        font-size: 15px
    &__week
        display: flex
        align-items: center
        justify-content: space-between
    &__day-names
        margin-bottom: 0
        margin-top: 1rem 
        background-color: #EDEFF4
        border-radius: 3px
        display: flex
        align-items: center
        justify-content: space-between
        padding: 10px 6px
    &__day-name
        font-weight: 600
        font-size: 14px
        color: #6D798E
        text-transform: capitalize
        width: 25px
        height: 25px
        &--active
            color: #2F4267
    &__day
        color: #2F4267
        font-weight: 600
        font-size: 14px
        width: 35px
        height: 35px
        margin: 0
        border-radius: 3px
        margin-bottom: 0.3rem
        line-height: 35px
        &+&
            margin-left: 0.3rem
        &--outside-month
            font-weight: 400
            color: #8E99AD
        &--selected
            color: #fff
            position: relative
            background-color: transparent
            background-image: radial-gradient(circle, $turquoise 48%, transparent 53%)

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover
    border-radius: 3px
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected
    background-image: radial-gradient(circle, $turquoise 48%, transparent 53%)
    background-color: transparent
    border-radius: 3px
    color: #fff
.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover
    background: #1CB6C1

.react-datepicker__navigation-icon--next::before,
.react-datepicker__navigation-icon--previous::before
    border-width: 2px 2px 0 0
    border-color: #1CB6C1
    height: 8px
    width: 8px
.react-datepicker__navigation:hover *::before
    border-color: #1CB6C1

.react-datepicker__navigation
    top: 0.5rem
    &--next
        right: 0.5rem
    &--previous
        left: 0.5rem

.react-datepicker__input-container input,.react-datepicker-ignore-onclickoutside
    border: 1px solid #C5CBD9
    border-radius: 5px
    font-weight: 700
    font-size: 15px
    line-height: 20px
    color: #2F4267
    padding: 6px 5px
    outline: none
    width: 100%
    &:hover
        cursor: pointer
        background-color: #EDEFF4

.custom-datepicker
    // margin-right: 9px
    width: 100%
// .input-and-button .react-datepicker-wrapper
//     margin-right: 9px

.custom-datepicker
    position: relative
    
    .picker-icon
        position: absolute
        bottom: 9px
        right: 9px
        pointer-events: none
        
.react-datepicker_big .react-datepicker__input-container input
    font-size: 1rem
    line-height: 1.5
    padding: 0.375rem 0.75rem