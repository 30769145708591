.mainParams{
    display: flex;
    border-bottom: 1px solid #CACBCD;
    padding-bottom: 10px;
    gap: 16px;
}

.ctnColumn{
    display: flex;
    gap: 17px;
    @media screen and (max-width: 1100px) {
        flex-direction: column;
    }

}

.columns{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    border-right: 1px solid #CACBCD;
    @media screen and (max-width: 1100px) {
        border-bottom: 1px solid #CACBCD;
        border-right: none;
        padding-bottom: 15px;
    }
}

.item{
    display: flex;
    gap: 10px;
    font-size: 17px;
}