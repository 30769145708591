.view-container
  background-color: #fff
  padding: 5px 0
  flex: 1 1 auto
  display: flex
  flex-direction: column
  overflow: hidden
  position: relative

.car-navs
  display: flex
  align-items: center
  justify-content: space-between
  border-bottom: 1px solid #C5CBD9
  padding: 0
  margin-bottom: 1rem
  .car-nav
    text-decoration: none
    height: 100%
    display: inline-block
    transition: 0.1s all ease
    span
      font-weight: 400
      font-size: 11px
      color: #2F4267
      text-transform: uppercase
      position: relative
      height: 100%
      display: inline-block
      padding: 9px 0
    &:hover
      span
        &::before
          content: ''
          position: absolute
          bottom: -0.5px
          left: 0
          height: 3px
          width: 100%
          background-color: #8E99AD
    &--active
      span
        font-weight: 700
        &::before
          content: ''
          position: absolute
          bottom: -0.5px
          left: 0
          height: 3px
          width: 100%
          background-color: #8E99AD

.vehicle-view
  font-family: "Open Sans", sans-serif
  max-width: 412px
  width: 100%

.view-goback
  display: flex
  border-bottom: 4px solid #EDEFF4
  margin-bottom: 16px
  justify-content: space-between
  align-items: center
  min-height: 40px
  .goback
    text-decoration: none
    font-weight: 700
    font-size: 14px
    text-transform: uppercase
    color: #2F4267
    display: flex
    align-items: center
    background-color: #fff
    border: none
    margin-left: -10px
    padding: 6px 10px
    transition: all 0.1s ease
    border-radius: 5px
    &:hover
      background-color: #EDEFF4
      cursor: pointer
    span
      margin-left: 10px
    &:active
      transform: translateY(1px)

.view-settings
  border: none
  background-color: #fff
  transition: 0.1s all ease
  height: 100%
  display: flex
  align-items: center
  svg
    fill: #8E99AD
  &:hover
    cursor: pointer
    svg
      fill: #4F6288
  &:active
    transform: translateY(1px)

.vehicle-header
  margin-bottom: 10px

.vehicle-comment
  background: #EDEFF4
  border-radius: 3px
  padding: 10px
  margin-bottom: 1rem
  color: #0B0C10
  font-size: 12px
  line-height: 1.2

.date-templates
  margin-bottom: 1rem
  display: flex
  align-items: center
  .date-template
    border: 1px solid transparent
    border-radius: 5px 8px
    background-color: #FFFFFF
    padding: 3px 5px
    line-height: 1
    font-weight: 400
    font-size: 14px
    transform: 0.1s all ease
    color: #2F4267
    &--active
      font-weight: 700
      background-color: #EDEFF4
      border-color: #EDEFF4
    &:hover
      background-color: #EDEFF4
      cursor: pointer
      border-color: #EDEFF4
    &:active
      transform: translateY(1px)

.view-status
  margin-bottom: 2rem
  line-height: 1
  &--no-margin
    margin-bottom: 0.5rem
  &__timestamp
    background: #EDEFF4
    border-radius: 3px
    padding: 5px 10px
    font-size: 12px
    line-height: 13px
    text-transform: uppercase
    color: #2F4267
    margin-bottom: 1rem
    font-family: "Open Sans", sans-serif
    max-width: 412px

.view-status__table
  display: flex
  width: 100%
  flex: 1 1 auto
  justify-content: space-between
  align-items: flex-start
  margin-bottom: 2rem
  font-family: "Open Sans", sans-serif
  &-title
    font-size: 11px
    text-transform: uppercase
    color: #2F4267
    font-weight: 700
  &-speed
    font-weight: 800
    font-size: 15px
    color: #2F4267
    &--purple
      color: #7E48B3
    span
      text-transform: none
      font-weight: 400
  &-value
    font-weight: 400
    font-size: 12px
    text-transform: capitalize
    color: #2F4267
    margin-top: 0.4rem
    line-height: 1

.view-status__info
  display: flex
  flex-direction: column
  justify-content: space-between
  height: 34px

.view-status__column
  height: 34px
  display: flex
  flex-direction: column
  justify-content: space-between
  &--with-img
    flex-direction: row
    align-items: center

.view-status__img-container
  margin-right: 14px
.view-status__img
  border-radius: 3px

.view-status__address-container
  position: relative
  padding-left: 20px
  font-family: "Open Sans", sans-serif
  .view-status__coords
    font-weight: 400
    font-size: 12px
    line-height: 16px
    color: #2F4267
  .view-status__address
    font-weight: 700
    font-size: 12px
    line-height: 16px
    color: #2F4267
    margin-left: 10px
  .view-status__address-img
    position: absolute
    top: -2px
    left: 0

.view__header
  padding: 0 20px

.view__content
  flex: 1 1
  overflow-x: hidden
  overflow-y: auto
  min-height: 0
  min-width: 0
  padding-bottom: 1rem

.view__inner
  padding: 0 20px
  .reports
    border: 1px solid $light-grey
    border-radius: 5px
    padding: 5px 5px 15px
    position: relative
    &::after
      content: ''
      position: absolute
      left: 0
      top: 30px
      height: 1px
      width: 100%
      background-color: $light-grey