@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&family=Raleway:wght@400;500;700&display=swap')
$color1: #51B56D
$color2: #BF3353
$color3: #FF7D39
$color4: #62A1FF
$color5: #F0F0F0
$color6: #5B25D1
$bg1: #191919
$bg2: #1e1e1e
$bg3: #333333
$fontFamilyRaleway: 'Raleway', sans-serif
$fontFamilyMontserrat: 'Montserrat', sans-serif

.a-wrapper ::-webkit-scrollbar
  width: 10px

.a-wrapper ::-webkit-scrollbar-track
  background-color: $bg1


.a-wrapper ::-webkit-scrollbar-thumb
  background-color: $color5

.a-wrapper
  font-family: $fontFamilyRaleway
  font-size: 13px
  line-height: 15px
  height: 100%
  background-color: $bg1
  color: $color5
  padding-left: 200px

.a-side-bar
  position: fixed
  left: 0
  top: 0
  z-index: 10
  width: 200px
  height: 100vh
  padding: 20% 0px 80px 20px
  &__body
    height: 100%
    background-color: $bg1
  &__list
    position: relative
  &__item
    display: flex
    text-decoration-line: none
    position: relative
    padding: 20px 0 20px 20px
    cursor: pointer
    margin-bottom: 10px
    &.active
      &:before
        width: 100%
      &:after
        opacity: 1
    &:before
      transition: 0.2s ease width
      border-radius: 15px 0 0 15px
      content: ''
      position: absolute
      width: 0
      right: 0
      top: 0
      height: 100%
      background: linear-gradient(90deg, rgb(230, 220, 200) -37%, rgb(25, 25, 25) 104%)
    &:after
      content: ''
      top: 0
      right: -3px
      width: 6px
      border-radius: 2px
      height: 100%
      position: absolute
      background-color: $color1
      opacity: 0
      transition: 0.2s ease opacity
    &:hover
      &:before
        width: 100%
      &:after
        opacity: 1
  &__link
    position: relative
    z-index: 1
    color: $color5
    text-decoration-line: none
    &:hover
      color: $color5

.a-body
  background-image: url("../images/bg.png")
  border-top-left-radius: 60px
  border-bottom-left-radius: 60px
  height: inherit
  padding: 20px 0 30px 0
  &__content
    height: 100%
    overflow: auto
    padding: 30px 50px
.col-device
  margin-bottom: 20px

.a-device
  position: relative
  border-radius: 15px
  background-color: $bg1
  padding-bottom: 10px
  display: flex
  flex-direction: column
  flex: 1
  height: 100%
  color: white
  &--archive
    opacity: 0.6
    cursor: not-allowed
  &__header
    padding: 10px 10px
    font-size: 14px
    line-height: 16px
    display: flex
    justify-content: space-between
    margin-bottom: 10px
    background-color: $bg3
    border-radius: 15px
  &__header-col
    display: flex
    font-family: $fontFamilyMontserrat
    span
      display: inline-block
      font-weight: bold
      margin-right: 5px
  &__body
    padding: 0 10px
  &__phone
    text-align: center
    font: 400 14px/17px $fontFamilyMontserrat
    margin-bottom: 10px
  &__code
    background-color: $bg3
    padding: 5px
    border-radius: 10px
    text-transform: uppercase
    text-align: center
    font: 500 15px/17px $fontFamilyRaleway
    cursor: pointer
  &__archived
    position: absolute
    top: 0px
    right: 0px
    width: 10px
    height: 10px
    border-radius: 50%
    z-index: 10
    &--active
      background-color: $color1
    &--no-active
      background-color: $color2
  &__footer
    padding: 10px 5px 0 5px
    justify-content: space-between
    display: flex
  &__btn
    color: $color5
    font: 700 13px/15px $fontFamilyRaleway
    padding: 5px 10px
    margin: 5px

.a-device-btn-delete
  background-color:  $color2
  &:hover, &:focus, &:active
    color: $color5
    background-color:  $color2
.a-device-btn-edit
  background-color: $color6
  &:hover, &:focus, &:active
    color: $color5
    background-color: $color6!important

.btn-device-add
  color: $color5
  padding: 10px 15px
  font: 700 18px/20px $fontFamilyRaleway
  margin-bottom: 30px
  background-color: $color6
  &:hover, &:focus, &:active
    background-color: $color6

.modal-device
  .modal-content
    background-color: $bg3
  .modal-title
    color: $color5
    font: 500 18px/20px $fontFamilyRaleway

.a-bg-warning
  border-radius: 10px
  padding: 2px 3px
  font: 500 13px/15px $fontFamilyMontserrat
  margin: 5px 0 0 0
  color: $color2

.a-input-group
  display: flex
  flex-direction: column
  margin-bottom: 15px

.a-input
  background-color: $bg2
  border-radius: 10px
  width: 100%
  height: 35px
  padding: 5px 10px
  border: none
  outline: none
  color: $color5
  font: 400 14px/16px $fontFamilyMontserrat

.btn-code
  font: 400 14px/15px $fontFamilyRaleway
  margin-top: 5px
  background-color: $color1
  color: $color5
  border-radius: 10px
  &:hover, &:focus, &:active
    background-color: $color1
    color: $color5

.admin
  &__user
    padding: 0.5rem
    border-radius: 10px
    background-color: #fff
    margin-bottom: 1rem
    gap: 0.4rem
    display: flex
    flex-direction: column



