.sensors__naked
    display: flex
    flex-wrap: wrap
    gap: 0.5rem
.sensor__naked
    width: 46px
    height: 18px
    display: flex
    align-items: center
    justify-content: center
    background-color: #fff
    outline: 1px solid #41A95F
    outline-offset: -1px
    border: none
    color: #fff
    border-radius: 5px
    font-size: 11px
    transition: 0.1s all ease
    &--red
        outline-color: #e12c2c
        outline-width: 2px
        .wheel__limits::before
            background-color: #e12c2c
            width: 2px
    &--yellow
        outline-color: #F18101
        outline-width: 2px
        .wheel__limits::before
            width: 2px
            background-color: #F18101
    .wheel-value
        color: #000
        font-size: 11px

.wheel__limits
    display: flex
    width: 100%
    height: 100%
    justify-content: space-between
    position: relative
    .wheel__limits-value
        line-height: 16px
        width: 50%
        text-align: center
        color: #000
        font-weight: 400
        font-size: 10px
    &::before
        content: ''
        position: absolute
        width: 1px
        height: 100%
        background-color: #41A95F
        top: 0
        left: 50%
        margin-left: 0.5px