.vehicles
  padding: 15px 20px
.vehicle
  margin-bottom: 1rem
  display: flex
  align-items: center
  justify-content: space-between
  position: relative
  transition: all 0.1s ease
  &:last-of-type
    margin-bottom: 0
  &:hover
    .open__icon
      fill: $middle-grey
  .vehicle-wrapper
    padding: 10px 0 5px 12px
    flex-grow: 1
    background-color: #FFFFFF
    border-radius: 5px
    border: 1px solid $light-grey
    display: flex
    align-items: center
    justify-content: space-between
    position: relative
    width: 100%
    &:after
      content: ''
      position: absolute
      height: 100%
      width: 3px
      background-color: transparent
      top: 0
      left: -1px
      border-radius: 5px 0 0 5px
  .vehicle__link
    text-decoration: none
    position: relative
    color: #000
    width: calc( 100% - 28px )
    .vehicle__state
      display: flex
      align-items: center
      width: 100%
      overflow: hidden
      .vehicle__icon
        width: 30px
      .vehicle__address
        font-size: 10px
        line-height: 14px
        font-weight: 500
        margin-left: 6px
        color: $dark-grey
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        padding-right: 10px
        flex: 1
  &:hover .vehicle-wrapper
    box-shadow: 5px 5px 10px -10px rgba(0,0,0,0.5)
    &:after
      background-color: $middle-grey
      transition: all 0.2s

.vehicle-menu__container
  width: 25px
  height: 50px
  height: 46px
  font-size: 0
.vehicle-menu
  display: flex
  flex-direction: column
  position: absolute
  bottom: -41px
  right: -3px
  padding: 16px 26px 10px 10px
  margin: 0
  background-color: #ffffff
  border-radius: 5px
  box-shadow: 2px 2px 10px -1px rgba(0,0,0,0.5)
  z-index: 10
  &__open
    border: none
    background-color: transparent
    height: 100%
    width: 100%
    .open__icon
      fill: $grey
  &__close
    position: absolute
    top: 5px
    right: 5px
    border: none
    background-color: transparent
    border-radius: 3px
    display: flex
    align-items: center
    justify-content: center
    padding: 5px
    &:hover
      background-color: #EDEFF4
  &__button
    border: none
    font-weight: 600
    font-size: 11px
    text-transform: uppercase
    padding: 5px 10px
    background-color: #ffffff
    transition: all 0.05s ease
    border-radius: 10px
    text-align: left
    color: #2F4267
    text-decoration: none
    margin: 3px 0
    &:hover
      background-color: $grey
      color: #fff

.vehicle__info
  display: flex
  align-items: center
  font-size: 12px
  font-weight: 400
  margin-bottom: 10px
.vehicle__full-number
  display: flex
  align-items: center
  height: 22px
  border-radius: 5px
  border: 1px solid $light-grey
  margin-right: 12px
  font-weight: 700
  line-height: 1
  .vehicle__number
    padding: 0 8px
    position: relative
    &::before
      content: ''
      position: absolute
      height: 20px
      width: 1px
      background-color: $light-grey
      top: -4px
      right: 0
  .vehicle__region
    padding: 0 8px


.vehicle.active 
  box-shadow: 5px 5px 10px -10px rgba(0,0,0,0.5)
.vehicle.active::after
  background-color: $middle-grey
  transition: all 0.2s
        