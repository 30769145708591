.aside 
  width: 100%
  display: flex
  color: $dark-grey
  margin: 0 auto
  position: absolute
  top: 0
  left: 0
  bottom: 0
  background-color: #fff
  padding-bottom: 2rem
  flex-direction: column
.report
  width: 100%
  display: flex
  color: $dark-grey
  margin: 0 auto
  position: absolute
  top: 0
  left: 0
  bottom: 0
  background-color: #fff
  padding-bottom: 2rem
  .report_box
    display: flex
    flex-direction: column
    margin: 30px auto 0
    max-width: 1170px
    padding: 0 20px
    .report_head
      display: flex
      flex-direction: column
      align-items: center
      .report_title
        font-weight: 400
        font-size: 34px
        position: relative
    .report_body
      display: flex
      flex-direction: column
      .report_day
        margin-bottom: 20px
        .report_event--not
          display: flex
          justify-content: center
          align-items: center
          font-weight: 400
          font-size: 15px
          color: $some-grey
          padding: 0 0 18px
          margin-top: 20px
          border-bottom: 1px solid $light-light-grey
      .report_counts
        display: flex
        margin-top: 25px
      .report_main
        position: relative
        padding-bottom: 20px
        margin-bottom: 28px
        &:before  
          position: absolute
          content: ''
          background: $light-light-grey
          height: 4px
          width: 100%
          bottom: 0
          left: 0
      .count_events
        margin-bottom: 15px
.report_download
  position: absolute
  background: $light-light-grey
  border: 1px solid $light-light-grey
  border-radius: 15px
  color: $dark-grey
  left: 101%
  top: 20px
  font-weight: 700
  font-size: 11px
  text-transform: uppercase
  padding: 5px 40px
  white-space: nowrap
  &:hover
    background-color: $turquoise
    color: #fff
    transition: all 0.2s ease
.report_data
  display: grid
  grid-template-columns: repeat(2, 1fr)
  gap: 20px
  width: 100%
.report_info-title 
  background-color: $light-light-grey
  border-radius: 5px 
  min-height: 35px
  grid-column-start: 1
  grid-column-end: 3
  display: grid
.report_count
  display: flex
  flex-direction: column
  margin: 0 auto 24px
  font-size: 32px
  color: $grey
  font-weight: 300
  .report_count-p
    display: flex
    font-size: min(24px, calc(20px + 4 * ((100vw - 1140px) / 780 )))
    line-height: 27px
    align-items: baseline
.report_info
  display: grid
  grid-template-columns: repeat(2, 1fr)
  grid-template-rows: auto 1fr 1fr
  .report_column  
    display: grid
    align-items: stretch
    padding: 12px 10px 10px
    grid-template-rows: 1fr 1fr
    .report_column-item
      font-size: 12px
      padding: 5px 0 
      text-transform: uppercase
      line-height: 16px
    .report_13
      font-size: 13px
      align-self: flex-start 
      padding: 0
    .report_comment
      font-weight: 400
      font-size: 13px
      line-height: 18px
      color: black
      text-transform: none
  .report_column_day
    grid-template-rows: 1fr auto
  .report_column--border 
    border-bottom: 1px solid $light-grey
  .report_column--big
    grid-column-start: 1
    grid-column-end: 3
.report_info_comment
  grid-template-columns: 1fr minmax(auto, 1fr)
.report_info-three    
  display: flex  
  justify-content: space-between
  .report_column--small
    grid-column-start: 1
    grid-column-end: 2
.report_data-border
  border-bottom: 1px solid $light-grey    
.report_sensors 
  display: flex
  flex-direction: column
  justify-content: center
  margin-bottom: 32px
  .report_sensors-box
    display: flex  
    padding: 0 10px
    height: 120px
    margin-top: 8px
    .report_sensors-info
      display: flex 
      flex-direction: column
      width: 165px
      align-self: center
      .sensors_row
        margin: 12px 0
        font-size: 12px
        text-transform: uppercase
    .report_sensors-date
      display: flex 
      .sensor
        display: flex
        flex-direction: column
        align-items: center
        margin: 0 5px
        &:first-of-type
          margin-left: 0
        &:last-of-type
          margin-right: 0
        .sensor_number
          margin-bottom: 8px
          font-size: 12px
        .sensor_params
          display: flex
          flex-direction: column
          border: 1px solid $light-grey
          border-radius: 5px
          align-items: center
          font-weight: 700  
          font-size: 13px
          .sensor_pressure-max
            border-bottom: 1px solid $light-grey
            padding: 13px 16px
          .sensor_temp 
            padding: 12px 16px
      
.report_title
  font-weight: 700
  font-size: 15px
  padding: 10px  
.report_bold
  font-weight: 700
.report-thin
  font-weight: 300
.report_blue
  color: $turquoise
  font-size: 32px  
  line-height: 27px
.report_padding
  padding-right: 10px

.report_date
  display: flex
  position: relative
  z-index: 2
  width: 100%
  justify-content: center
  margin: 20px 0
  &::before
    position: absolute
    content: ''
    width: 100%
    height: 4px
    background-color: $light-light-grey
    left: 0px
    bottom: 45%
    z-index: -1
  .report_date-title
    font-size: 13px
    text-transform: uppercase
    padding: 0 15px
    background-color: white
    color: #2F4267
.report_date--violation
  margin: 12px 0 6px
.report_data--single
  display: flex
  .report_info 
    .report_column
      display: flex
      flex-direction: column
  .report_info-title
    display: flex
.report_column--big
  grid-column-start: 1
  grid-column-end: 3