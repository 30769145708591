.ctnModal{
    display: "flex";
    margin: "auto";
    justify-content: "center";
    align-items: "center";
    position: "fixed";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: "500px";
    background: "rgba(0, 0, 0, 0.5)";
}

.ctnModal-content{}

.ctnButton{
    display: flex;
    justify-content: center;
    gap: 20px;
}