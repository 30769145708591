.containerCar{
    margin: 10px;
}
.containerCar:hover{
    border-radius: 4px;
    box-shadow: 1px 1px 1px #4f6288a1;
}
.containerCarInstall{
    margin: 10px;
}
.titleSearchCar{
    display: flex;
    justify-content: center;
    margin: 20px;
}
.ctnButton{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.ctnButtonFile{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.modalButton{
    margin: 0;
}
.ctnModel{
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}
.title{
    margin-bottom: 7px;
    font-size: 15px;
    color: #2F4267;
}
.data{
    margin: 6px 0 10px 0;
    font-size: 14px;
    color: #2F4267;
}
.infoData{
    width: 200px;
    height: 50px;
}
.ctnInfoTyres{
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;
    
}
.ctn_model_title{
   display: flex;
   gap: 8px;
   margin-top: 30px;
   cursor: pointer;
}
.model_title{
    padding-left: 5px;
}
.imgSkip{
    padding-bottom: 1px;
    transition: transform 0.5s ease;
}
.imgSkip.rotate{
    transform: rotate(180deg);
}

.input{
    display: none;
    visibility: hidden;
}


.tableFile{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.ctnFile{
    display: flex;
    justify-content: space-between;
    width: 415px;
    background: rgb(237, 239, 244);
    margin: 2px;
}
.infoFile{
    display: flex;
}
.imgFile{
    margin: 11px 10px 11px 10px;
}
.basket{
    cursor: pointer;
    margin: 11px 10px 11px 0;
}
.fileName{
    width: 113px;
    overflow: hidden;
    margin: 11px 0 10px 0;
    color: rgb(47, 66, 103);
    font-family: Open Sans;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0%;
}
.input{
    display: none;
}
.ctnButton{
    display: flex;
    justify-content: center;
}
.modalValid{
    display: flex;
    margin: 4px 0 2px 4px;
}
.ctnButtonDelete{
    display: flex;
}

.ctnValidation{
    display: flex;
    justify-content: space-between;
    width: 230px;
    height: 36px;
    border-radius: 20px;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);
    background: rgb(255, 255, 255);
}
.text{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    margin: 10px 0 10px 10px;
    color: rgb(225, 44, 44);
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0%;
}
.ctnButton{
    display: flex;
    gap: 159px;
}
.buttonDeleteNo{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    width: 35px;
    height: 20px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    border-radius: 20px;
    background: rgb(237, 239, 244);
    color: rgb(151, 160, 179);
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0%;
    margin: 9px 9px 7px 0;
    padding: 0 10px 2px 9px;
    &:hover{
        display: flex;
        justify-content: center;
        border: 0;
        align-items: center;
        color: rgb(255, 255, 255);
        font-family: Open Sans;
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0%;
        width: 35px;
        border-radius: 20px;
        background: rgb(151, 160, 179);
        margin: 9px 9px 7px 0;
        padding: 0 10px 2px 9px;
    }
}

.not_found{
    display: flex;
    justify-content: center;
}