.view-model
    display: flex
    align-items: center
    justify-content: center
    position: relative
    &__container
        display: flex
        align-items: center
        justify-content: center
        flex-direction: row-reverse
        height: 165px
    .center
        position: absolute
        height: 100%
        width: 2px
        left: 50%
        margin-left: -1px
        top: 0
        height: 100%
        background-color: red
    .wheels
        position: absolute
        top: 0
        left: 0
        height: 100%
        width: 100%
        display: flex
        align-items: center
        justify-content: center
        flex-direction: row-reverse

.wheel-container
    height: 16px
    width: 64px
    display: flex
    align-items: center
    justify-content: center
.wheel-container-general_tc
    height: 17px
    width: 45px
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
.wheel-container-general_trailer
    height: 16px
    width: 60px
    display: flex
    margin: 5px
    align-items: center
    justify-content: center
.wheel
    border-radius: 5px
    height: 16px
    width: 64px
    font-weight: 400
    font-size: 11px
    text-align: center
    font-weight: 700
    transition: 0.1s background-color ease
    display: flex
    align-items: center
    justify-content: center
    .hidden-index
        opacity: 0
        transition: 0.1s all ease
    &--mini
        width: 46px
    &--mini_tyres
        width: 30px
    &--empty
        outline: 1px solid #41A95F
    &--empty--hover
        outline: 2px solid #41A95F  
        box-shadow: 0px 4px 1px #41A95F
    &--editable
        outline: 1px dashed #41A95F
        background-color: #fff
    &--filled
        background-color: #fff
        color: #000
        font-weight: 400
        outline: 1px solid #41A95F 
        outline-offset: -1px
    &--filled_tyres_position
        background-color: #fff
        color: #000
        font-weight: 400
        outline: 2px solid #41A95F 
        outline-offset: -1px
    &--color_filled
        background: rgb(196, 196, 196)
        font-weight: 400
        outline-offset: -1px
    &--color_filled-red
        background: #e12c2c
        font-weight: 400
        outline-offset: -1px
    &--color_filled-green
        background: #41A95F
        font-weight: 400
        outline-offset: -1px
    &--color_filled-orange
        background: #F18101
        font-weight: 400
        outline-offset: -1px
    &--editable:hover
        cursor: pointer
        background-color: #41A95F
        outline: 2px dashed #41A95F
        color: #fff
        .hidden-index
            opacity: 1

    &--red
        outline-width: 2px
        outline-color: #e12c2c
    &--yellow
        outline-width: 2px
        outline-color: #F18101
    &--withoutTyre
        outline: 1px solid $light-grey

.edit-model
    &__buttons
        display: flex
        align-items: center
        justify-content: center
        gap: 1rem
        margin-bottom: 2rem
    &__total
        text-align: center
        margin-bottom: 1rem

.sensors
    position: relative
    margin-bottom: 1.2rem

// .naked__container
//   margin-top: 1.4rem
//   margin-bottom: 2rem

.sensor-tabs
    margin: 0 auto
    margin-top: 0.5rem
    display: flex
    align-items: flex-start
    justify-content: center
    border-radius: 5px
    overflow: hidden
    max-width: 310px
.sensor-tabs--mini
    max-width: 120px
.sensor-tab
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    font-family: 'Open Sans'
    font-weight: 700
    font-size: 11px
    text-transform: uppercase
    color: #2F4267
    background-color: #EDEFF4
    border: none
    padding: 0
    display: flex
    align-items: center
    justify-content: center
    flex: 1 1 auto
    height: 36px
    transition: 0.1s all ease
    &__img
        fill: #2F4267
        transition: 0.1s all ease
    &--active, &:hover
        background-color: #1CB6C1

    &--active &__img, &:hover &__img
        fill: #fff

.sensors-from
    border-radius: 3px
    background-color: #EDEFF4
    padding: 3px 5px
    display: flex
    gap: 5px
    margin-bottom: 14px
    align-items: center
    width: 190px
    &__text
        font-size: 11px
        font-weight: 400
        color: #2F4267
        text-transform: uppercase
    &__date
        font-size: 11px
        font-weight: 700
        color: #2F4267
