.violations
  margin-top: 1rem
  .violation__head
    display: flex
    margin: 0 0 8px 0
    .violation__title
      padding-right: 20px  
      font-weight: 700
      color: #2F4267
      font-size: 15px   
    .violation__count
      color: #97A1B3
      font-size: 15px
      position: relative
      &::before
        content: ''
        height: 100%
        width: 1px
        top: 0
        left: -9px
        position: absolute
        background-color: $grey
  .violation__box
    display: flex
    flex-direction: column
    .violation__text
      align-self: center
      font-weight: 400
      font-size: 13px
      color: $some-grey
      padding: 20px 0
      border-bottom: 1px solid $some-grey
      border-top: 1px solid $some-grey
      margin-top: 20px
      width: 100%
      text-align: center  
.violation
  display: flex
  align-items: center
  padding: 14px 0
  &:hover
    cursor: pointer
  &+.violation
    border-top: 1px solid #E5E5E5
  .violation__date
    margin-right: 1rem
    font-weight: 400
    font-size: 15px
    color: #97A1B3
  .violation__container
    display: flex
    flex-direction: column
    gap: 10px
    flex-grow: 1
    .violation__part
      display: flex
      align-items: center
      flex-grow: 1
      .violation__info
        display: flex
        flex-direction: column
        justify-content: space-between
        flex-grow: 1
      .violation__icon
        margin-right: 15px
      .violation__wrapper
        display: flex
        flex-direction: column
      .violation__name
        font-weight: 700
        font-size: 13px
        color: #2F4267
      .violation__more
        display: flex
        font-weight: 500
        font-size: 13px
        color: #2F4267
        margin-top: 5px 
        text-transform: uppercase
        // justify-content: space-between
        .violation__sensor
          flex-basis: 65%
              
              // display: flex
              // margin-right: 10px