.modelContainer{
    margin: 36px 0 36px 0;
}

.ctNoTyre{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 120px;
}

.text{
    margin: 40px;
    color: rgb(47, 66, 103);
    font-family: Open Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 15px;
}

.ctnInfo{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.btnAddTyre{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 50px 10px 50px;
    border-radius: 50px;
    background: rgb(237, 239, 244);
    color: rgb(47, 66, 103);
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0%;
    text-align: left;
    text-transform: uppercase;
    border: none;
    &:hover{
        color: rgb(252, 253, 255);
        border-radius: 50px;
        background: rgb(28, 182, 193);
    }
}

.ctnConstructorTitle{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.carTittle{
    display: flex;
    justify-content: center;
    color: rgb(47, 66, 103);
    font-family: Open Sans;
    font-size: 17px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0%;
    margin-top: 50px;
}

.ctnInfoTyre{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8rem;
}

.reportCount{
    margin-right: 30px;
}

.toggleInfoSensor{
    position: absolute;
}

.ctnTittleAction{
    display: flex;
    align-items: center;
}

.ctnButton{
    background: #EDEFF4;
    border: 1px solid #EDEFF4;
    border-radius: 15px;
    color: #2F4267;
    left: 101%;
    top: 20px;
    font-weight: 700;
    font-size: 11px;
    text-transform: uppercase;
    padding: 5px 40px;
    white-space: nowrap;
    &:hover{
        background-color: #15A3AB;
        color: #fff;
        transition: all 0.2s ease;
    }
}

.ctnMenuAction{
    display: flex;
    flex-direction: column;
    position: relative;
}

.toggleMenu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    top: 30px;
    gap: 8px;
    padding-left: 8px;
    z-index: 99;
    width: 202px;
    height: 90px;
    border-radius: 3px;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.3);
    background: rgb(255, 255, 255);  
}

.ctnTitleMenu{
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 5px;
}

.ctnTextMenu{
    display: flex;
    font-size: 15px;
    cursor: pointer;
    &:hover{
        font-weight: 600;
    }
}